<template>
    <div>
        <div class="dropzone">
            <div class="dz-message"><span>Drag & Drop to Upload</span></div>
            <div class="fallback">
                <div class="input-group d-flex justify-content-center">
                    <label class="input-group-btn">
                        <span class="btn btn-primary">
                            Browse&hellip; <BFormFile :id="id" v-model="files" class="d-none" :accept="acceptedFiles" multiple @update:modelValue="onFilesAttach" :directory="null" />
                        </span>
                    </label>
                </div>
                <div class="row files align-items-center" v-for="(file, index) in files">
                    <div class="col ms-auto">{{ file.name }}</div>
                    <div class="col-auto">
                        <BButton variant="link" class="text-danger" @click="removedFile(index)">X</BButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import type { BlobUploadViewModel } from '@/types/models/order/blob-upload-view-model';
    import { orderService } from '@/services';

    const files = ref<Nullable<File[]>>([]);

    const emit = defineEmits([
        'fileRemoved',
        'fileUploaded',
        'certificateUploaded'
    ]);

    const props = defineProps({
        action: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        acceptedFiles: {
            type: String,
            default: 'image/*,.pdf,.zip,.rar'
        },
        maxFileSize: {
            type: Number,
            default: 20971520
        },
        maxFiles: {
            type: Number,
            default: 1
        },
        isTaxDropzone: {
            type: Boolean,
            default: false
        },
        isOrderNowDropzone: {
            type: Boolean,
            default: false
        },
    });

    onMounted(() => {
        files.value = [];
    });

    const errors = ref<string[]>([]);

    function onFilesAttach(items: File[]) {
        files.value?.forEach((file: File) => {
            emit('fileRemoved', file);
        });

        if (props.maxFiles < items.length)
            return;

        items.forEach((file: File) => {
            if (file.size > props.maxFileSize && (!errors || !errors.value.length))
                errors.value.push("Maximum file size is 20Mb");
        });

        if (!errors.value.length) {
            items.forEach((file: File) => {
                let formData = new FormData();

                formData.append('file', file);

                fetch(props.action, {
                    method: 'POST',
                    body: formData
                })
                    .then(response => response.json())
                    .then(data => {
                        if (props.isTaxDropzone || props.isOrderNowDropzone) {
                            if (props.isTaxDropzone) {
                                setBlobUrl(data);
                                emit('certificateUploaded', data);
                            }

                            if (props.isOrderNowDropzone)
                                emit('fileUploaded', { file: file, url: data });
                        }
                    });
            });
        }
    }

    async function removedFile(index: number) {
        if (index >= 0 && index < files.value!.length) {
            if (props.isOrderNowDropzone)
                emit('fileRemoved', files.value![index]);

            if (props.isTaxDropzone)
                await setBlobUrl('', true);

            files.value!.splice(index, 1);
        }
    }

    async function setBlobUrl(blobUrl: string, reset = false) {
        if (props.isTaxDropzone)
            reset ? await orderService.resetOrderBlobUrl() : await orderService.saveOrderBlobUrl({ blobUrl: blobUrl } as BlobUploadViewModel);
    }
</script>

<style lang="scss">
    .input-group-btn {
        .btn {
            height: 40px;
            line-height: 26px;
        }

        .input-group-text {
            display: none;
        }
    }

    .files {
        font-size: 14px;
    }
</style>